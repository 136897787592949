<template>
  <div class="col admin_regions_page_wrapper" >
    <div class="every_table_action_button">
      <button class="our_btn" @click="openAddDialog">
        <el-icon class="el-icon-plus"></el-icon>
        {{ $t("admin_dashboard_type.add_region_admin") }}
      </button>
    </div>
    <!-- Start All Regions  -->
    <server-table
      :DataItems="regionAdmins"
      :count="regionAdminsCount"
      @Refresh="Refresh"
      :useFilter="false"
      :enable_export="false"
      :action="false"
    >
      <template #columns>
        <el-table-column prop="name" :label="$t('admin_dashboard_type.name')">
          <template slot-scope="scope">
            <router-link :to="`/region-admin/${scope.row.id}`">
              {{ scope.row.name }}
            </router-link>
          </template>
          <!-- /region-admin/:id -->
        </el-table-column>
        <el-table-column prop="email" :label="$t('admin_dashboard_type.email')">
        </el-table-column>
        <el-table-column
          prop="country"
          :label="$t('admin_dashboard_type.country')"
        >
        </el-table-column>
        <el-table-column prop="city" :label="$t('admin_dashboard_type.city')">
        </el-table-column>
        <el-table-column
          prop="gender"
          :label="$t('admin_dashboard_type.gender')"
        >
          <template slot-scope="scope">
            <div>
              <span class="d-block" v-if="scope.row.gender == 1">ذكر</span>
              <span class="d-block" v-if="scope.row.gender == 2">أنثى</span>
              <span class="d-block" v-if="scope.row.gender == null">__</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="_">
          <template slot-scope="scope">
            <div class="icons_table_wrapper">
              <i
                @click="removeAdminRegion(scope.row.id)"
                class="fa fa-trash shadow-sm icon remove"
                v-tooltip.top-center="
                  $t('admin_dashboard_type.delete_region_admin')
                "
              ></i>
              <i
                class="fa fa-edit shadow-sm icon edit"
                @click="openEditAdminRegion(scope.row)"
                v-tooltip.top-center="
                  $t('admin_dashboard_type.edit_region_admin')
                "
              ></i>
            </div>
          </template>
        </el-table-column>
      </template>
    </server-table>
    <!-- End All Regions  -->

    <!-- Start Add Regions  -->
    <el-dialog
      :visible.sync="showAddRegionAdminDialog"
      :title="
        isEdit == false
          ? $t('admin_dashboard_type.add_region_admin')
          : $t('admin_dashboard_type.edit_region_admin')
      "
      transition="dialog-bottom-transition"
      width="70%"
      top="4vh"
      class="text-right"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="tabs_heading mb-3">
        <button
          :class="{ is_active: activeTabStudent == 1 }"
          class="our_buttons"
          @click="whichTabIsActive(1)"
        >
          {{
            isEdit == false
              ? $t("admin_dashboard_type.add_region_admin")
              : $t("admin_dashboard_type.edit_region_admin")
          }}
        </button>
        <button
          :class="{ is_active: activeTabStudent == 2 }"
          class="our_buttons"
          @click="whichTabIsActive(2)"
          v-if="isEdit == true"
        >
          {{ $t("admin_dashboard_type.change_password") }}
        </button>
      </div>
      <!-- Start Add Or Edit Region Admin -->
      <div v-if="activeTabStudent == 1">
        <el-form
          :model="RegionData"
          :rules="isEdit == false ? addRegionRules : addRegionRulesWhenEdit"
          ref="addAdminRegionForm"
          class="demo-ruleForm"
          label-position="top"

        >
          <div class="row" >
            <div class="col-md-6 " >
              <el-form-item :label="$t('admin_dashboard_type.name')" prop="name" >
                <el-input
                  :label="$t('admin_dashboard_type.name')"
                  v-model="RegionData.name"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.email')"
                prop="email"
                :rules="[
                  {
                    required: true,
                    type: 'email',
                    message: 'يجب ادخال البريد الالكتروني بشكل صحيح',
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.email')"
                  v-model="RegionData.email"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6" :class="{ 'd-none': isEdit == true }">
              <el-form-item
                :label="$t('admin_dashboard_type.password')"
                prop="password"
              >
                <el-input
                  :label="$t('admin_dashboard_type.password')"
                  v-model="RegionData.password"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.country')"
                prop="country"
              >
                <multiselect
                  :placeholder="$t('admin_dashboard_type.country')"
                  :searchable="true"
                  :show-labels="true"
                  :close-on-select="true"
                  v-model="RegionData.country"
                  :multiple="false"
                  :options="allCountries"
                ></multiselect>
              </el-form-item>
            </div>

            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.city')"
                prop="city"
              >
                <el-input
                  :label="$t('admin_dashboard_type.city')"
                  v-model="RegionData.city"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.gender')"
                prop="gender"
              >
                <el-radio-group v-model="RegionData.gender">
                  <el-radio :label="1" @click="RegionData.gender = 1">
                    {{ $t("admin_dashboard_type.male") }}
                  </el-radio>
                  <el-radio :label="2" @click="RegionData.gender = 2">{{
                    $t("admin_dashboard_type.female")
                  }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="creative_app_footer_dialog">
          <el-button
            @click="handlerFunction"
            class="when_submit_form save_from"
          >
            {{ $t("save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="handlerCloseFunction"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- Start Add Or Edit Region Admin -->
      <!-- Start Change Student password  -->
      <div v-if="activeTabStudent == 2">
        <el-form
          :model="passData"
          ref="editedPasswordRef"
          :rules="editedPasswordRules"
          label-position="top"
        >
          <div class="row">
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.password')"
                prop="password"
              >
                <el-input
                  v-model="passData.password"
                  style="width: 100%"
                  :placeholder="$t('admin_dashboard_type.password')"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="creative_app_footer_dialog">
          <el-button @click="savePassword" class="when_submit_form save_from">
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="handlerCloseFunction"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- Start Change Student password  -->
    </el-dialog>
    <!-- End Add Regions  -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  components: { ServerTable },
  data() {
    return {
      showAddRegionAdminDialog: false,
      activeTabStudent: 1,
      RegionData: {
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        country: null,
        city: null,
        gender: null,
      },
      addRegionRules: {
        name: [
          {
            required: true,
            message: "هذا الحقل مطلوب",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "هذا الحقل مطلوب",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "هذا الحقل مطلوب",
            trigger: "blur",
          },
        ],
        gender: [
          {
            required: true,
            message: "هذا الحقل مطلوب",
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: "هذا الحقل مطلوب",
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: "هذا الحقل مطلوب",
            trigger: "blur",
          },
        ],
      },
      addRegionRulesWhenEdit: {
        name: [
          {
            required: true,
            message: "هذا الحقل مطلوب",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "هذا الحقل مطلوب",
            trigger: "blur",
          },
        ],

        gender: [
          {
            required: true,
            message: "هذا الحقل مطلوب",
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: "هذا الحقل مطلوب",
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: "هذا الحقل مطلوب",
            trigger: "blur",
          },
        ],
      },
      isEdit: false,
      passData: {
        password: null,
        confirm_password: null,
      },
      editedPasswordRules: {
        password: [
          {
            required: true,
            message: "يجب ادخال كلمة المرور",
            trigger: "blur",
          },
        ],
        confirm_password: [
          {
            required: true,
            message: "يجب ادخال تاكيد كلمة المرور بنفس القيمه",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.Refresh();
  },
  computed: {
    ...mapGetters({
      loadingValue: ["schools/load"],
      regionAdmins: ["schools/regionAdmins"],
      regionAdminsCount: ["schools/regionAdminsCount"],
    }),
    offset() {
      if (this.$route.query.currentPage && this.$route.query.perPage) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },

    limit() {
      if (this.$route.query.perPage) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
    allCountries() {
      return this.$store.getters["user/allCountries"];
    },
    handlerFunction() {
      return this.isEdit == false ? this.addAdminRegion : this.EditAdminRegion;
    },
    handlerCloseFunction() {
      return this.isEdit == false ? this.closeAddDialog : this.closeEditDialog;
    },
  },
  methods: {
    Refresh(
      query = { offset: this.offset, limit: this.limit, order_by: "id" }
    ) {
      this.$store.dispatch("schools/getListOfRegionAdmins", { query: query });
    },
    openEditAdminRegion(item) {
      console.log(item);
      this.showAddRegionAdminDialog = true;
      this.RegionData = item;
      this.isEdit = true;
    },
    addAdminRegion() {
      this.$refs["addAdminRegionForm"].validate((valid) => {
        if (valid) {
          this.RegionData.confirm_password = this.RegionData.password;
          this.$store
            .dispatch("schools/handlerAddRegionAdmin", this.RegionData)
            .then(() => {
              this.showAddRegionAdminDialog = false;
              Swal.fire({
                text: `${this.$t("success_added")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: `${this.$t("ok")}`,
                buttonsStyling: false,
              });
              this.Refresh();
              this.RegionData.name = null;
              this.RegionData.email = null;
              this.RegionData.password = null;
              this.RegionData.confirm_password = null;
              this.RegionData.country = null;
              this.RegionData.city = null;
              this.RegionData.gender = null;
            });
        } else {
          return false;
        }
      });
    },
    EditAdminRegion() {
      this.$refs["addAdminRegionForm"].validate((valid) => {
        if (valid) {
          let afterUpdateRegionData = {
            name: null,
            email: null,
            password: null,
            confirm_password: null,
            country: null,
            city: null,
            gender: null,
          };

          this.$store
            .dispatch("user/save", {
              query: this.RegionData,
            })
            .then(() => {
              this.showAddRegionAdminDialog = false;
              this.isEdit = false;
              this.RegionData = afterUpdateRegionData;
              this.Refresh();
              Swal.fire({
                text: "تم التعديل للبيانات بنجاح",
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: "موافق",
                buttonsStyling: false,
              });
            });
        } else {
          return false;
        }
        // handlerEditRegionAdmin
      });
    },
    removeAdminRegion(id) {
      Swal.fire({
        text: "هل تريد حذف أدمن المنطقة ؟",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          console.log(id);
          // this.$store.dispatch("schools/deleteGroup", id).then((_) => {
          //   this.Refresh();
          //   Swal.fire({
          //     text: 'تم الجذف بنجاح',
          //     icon: "success",
          //     confirmButtonClass: "btn btn-success btn-fill",
          //     buttonsStyling: false,
          //   });
          // });
          Swal.fire({
            text: "تم الجذف بنجاح",
            icon: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        }
      });
    },
    openAddDialog() {
      this.activeTabStudent = 1;
      let afterUpdateRegionData = {
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        country: null,
        city: null,
        gender: null,
      };
      this.isEdit = false;
      this.RegionData = afterUpdateRegionData;
      this.showAddRegionAdminDialog = true;
    },
    closeAddDialog() {
      this.showAddRegionAdminDialog = false;
      this.activeTabStudent = 1;
    },
    closeEditDialog() {
      let afterUpdateRegionData = {
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        country: null,
        city: null,
        gender: null,
      };

      this.Refresh();
      this.showAddRegionAdminDialog = false;
      this.isEdit = false;
      this.activeTabStudent = 1;
      this.RegionData = afterUpdateRegionData;
    },
    whichTabIsActive(num) {
      this.activeTabStudent = num;
    },
    savePassword() {
      this.$refs["editedPasswordRef"].validate((valid) => {
        if (valid) {
          this.passData.confirm_password = this.passData.password;

          this.$store
            .dispatch("user/handler_update_student_password", {
              id: this.RegionData.id,
              query: this.passData,
            })
            .then(() => {
              this.showAddRegionAdminDialog = false;
              this.isEdit = false;
              this.activeTabStudent = 1;
              (this.passData.password = null),
                (this.passData.confirm_password = null),
                Swal.fire({
                  text: "تم تغيير كلمة المرور بنجاح",
                  icon: "success",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false,
                });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.admin_regions_page_wrapper {
  .icons_table_wrapper {
    display: flex;
    gap: 8px;
    justify-content: end;
    padding: 5px 0;
    .icon {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      cursor: pointer;
      &.edit {
        background-color: #409eff;
        color: #fff;
      }
      &.remove {
        background-color: #fb404b;
        color: #fff;
      }
    }
  }
}
.tabs_heading {
  display: flex;
  gap: 15px;
  padding: 10px 0;
  border-bottom: 3px solid #e4e4e4;
  .our_buttons {
    border: none;
    background: none;
    color: #777;
    position: relative;
    &::before {
      position: absolute;
      right: 0;
      content: "";
      background: #409eff;
      width: 100%;
      height: 3px;
      bottom: -12px;
      display: none;
    }
    &.is_active {
      &::before {
        display: block;
      }
    }
  }
}
.every_table_action_button {
  padding: 10px 0;
  .our_btn {
    background-color: #409eff;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    transition: 0.5;
    border: 1px solid #409eff;
    &:hover {
      opacity: 0.8;
    }
  }
}
::v-deep .el-form--label-top .el-form-item__label {
  width: 100%;
  text-align: right;
}
::v-deep .el-form-item__error {
  right: 0;
  left: unset;
}
::v-deep .el-form-item {
  text-align: right;
}
.creative_app_footer_dialog {
  display: flex;
  margin-top: 15px;
  justify-content: end;
  padding: 10px;
  gap: 10px;
  .when_submit_form {
    min-width: 150px;
    text-align: center;
    transition: 0.3s;
    &.save_from {
      border: 1px solid #409eff;
      background-color: #409eff;
      color: #fff;
    }
    &.cancel_from {
      border: 1px solid #fb404b;
      background-color: #fb404b;
      color: #fff;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
</style>
